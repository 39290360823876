var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.form.receiveItems,
          columns: _vm.columns,
          size: "small",
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          },
          pagination: {
            showSizeChanger: true,
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            }
          },
          scroll: { y: 700 }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "a-button-group",
                  [
                    _c("a-button", {
                      attrs: {
                        icon: "plus",
                        type: "primary",
                        disabled: !_vm.form.purchaseOrderId
                      },
                      on: { click: _vm.addRow }
                    }),
                    _c("a-button", {
                      attrs: {
                        icon: "delete",
                        type: "danger",
                        disabled: !_vm.form.purchaseOrderId
                      },
                      on: { click: _vm.handleDeleteRow }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
            }
          },
          {
            key: "productCode",
            fn: function(text, row) {
              return [
                row.source === "manual"
                  ? _c("SelectProductByType", {
                      staticClass: "w-100",
                      attrs: {
                        "option-label": "code",
                        "product-type": _vm.poProductType
                      },
                      on: {
                        "update:meta": function($event) {
                          return _vm.onChangeProduct($event, row)
                        }
                      },
                      model: {
                        value: row.productCode,
                        callback: function($$v) {
                          _vm.$set(row, "productCode", $$v)
                        },
                        expression: "row.productCode"
                      }
                    })
                  : _c("span", [
                      _vm._v(" " + _vm._s(row.productCode || "-") + " ")
                    ])
              ]
            }
          },
          {
            key: "productName",
            fn: function(text, row) {
              return [
                row.source === "manual"
                  ? _c("SelectProductByType", {
                      staticClass: "w-100",
                      attrs: {
                        "option-label": "code",
                        "product-type": _vm.poProductType
                      },
                      on: {
                        "update:meta": function($event) {
                          return _vm.onChangeProduct($event, row)
                        }
                      },
                      model: {
                        value: row.productName,
                        callback: function($$v) {
                          _vm.$set(row, "productName", $$v)
                        },
                        expression: "row.productName"
                      }
                    })
                  : _c("span", [
                      _vm._v(" " + _vm._s(row.productName || "-") + " ")
                    ])
              ]
            }
          },
          {
            key: "brand",
            fn: function(text, row) {
              return [
                row.source === "manual"
                  ? _c("master-product-merk-select", {
                      staticClass: "w-100",
                      model: {
                        value: row.merk,
                        callback: function($$v) {
                          _vm.$set(row, "merk", $$v)
                        },
                        expression: "row.merk"
                      }
                    })
                  : _c("span", [_vm._v(" " + _vm._s(row.merk || "-") + " ")])
              ]
            }
          },
          {
            key: "receiptQty",
            fn: function(text, row) {
              return [
                _c("a-input-number", {
                  staticClass: "w-100",
                  attrs: {
                    min: 0,
                    parser: _vm.reverseFormatNumber,
                    formatter: _vm.formatterNumber,
                    precision: _vm.storeBaseDecimalPlace
                  },
                  model: {
                    value: row.qty,
                    callback: function($$v) {
                      _vm.$set(row, "qty", _vm._n($$v))
                    },
                    expression: "row.qty"
                  }
                })
              ]
            }
          },
          {
            key: "receiptLocation",
            fn: function(text, row) {
              return [
                _c("SelectRack", {
                  staticClass: "w-100",
                  attrs: { "branch-id": _vm.form.branchWarehouseId },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeRack($event, row)
                    }
                  },
                  model: {
                    value: row.locationReceived,
                    callback: function($$v) {
                      _vm.$set(row, "locationReceived", $$v)
                    },
                    expression: "row.locationReceived"
                  }
                })
              ]
            }
          },
          {
            key: "trackAsAsset",
            fn: function(text, row) {
              return [
                _c("a-switch", {
                  model: {
                    value: row.trackAsAsset,
                    callback: function($$v) {
                      _vm.$set(row, "trackAsAsset", $$v)
                    },
                    expression: "row.trackAsAsset"
                  }
                })
              ]
            }
          },
          {
            key: "notes",
            fn: function(text, row) {
              return [
                _c(
                  "a-badge",
                  { attrs: { count: row.itemAssets.length } },
                  [
                    _c("a-button", {
                      attrs: { icon: "bars" },
                      on: {
                        click: function($event) {
                          return _vm.viewItemAssets(row)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("ModalAssetLine", {
        attrs: { visible: _vm.modalAssetState.visible },
        on: { close: _vm.handleCloseModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }